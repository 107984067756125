@font-face {
  font-family: "Open Sans";
  src: url("../assets/fonts/OpenSans-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../assets/fonts/OpenSans-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../assets/fonts/OpenSans-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../assets/fonts/OpenSans-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

.flex-row {
  flex-direction: row !important;
}

svg {
  vertical-align: middle;
}

input {
  overflow: visible;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.cat_btn_drop_down_menu {
  position: absolute !important;
  left: 0px !important;
  margin: 0px !important;
  right: auto !important;
  bottom: auto !important;
  transform: translate3d(0px, 48.5px, 0px) !important;
}

.spinner-border {
  border: 0.25em solid #000;
  border-right-color: transparent;
}

.btn-group {
  direction: ltr;
  align-self: center;
  display: flex;
  margin-top: 0.5rem;
  border: 2px solid orange;
  border-radius: 1.75vh;
}

.modal-open .modal {
  display: flex !important;
  justify-content: center;
}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

@media (max-width: 480px) {
  .Toastify__toast-container {
    left: 16px;
    bottom: 16px;
  }
}

.Toastify__toast {
  width: 320px;
  min-height: unset;
  padding: 10px;
  background: #000000;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(60, 66, 87, 0.24), 0px 0.5px 1px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  color: #ffffff;
}

@media (max-width: 480px) {
  .Toastify__toast {
    width: calc(100vw - 32px);
    margin-bottom: 16px;
  }
}

.Toastify__toast-body {
  margin: 0;
}

.Toastify__close-button--default {
  opacity: 1;
}

.Toastify__close-button > svg {
  fill: #acafb1;
}

ins[data-ad-status="unfilled"] {
  display: none;
}

@-webkit-keyframes skeleton-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes skeleton-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* Remove Arrows/Spinners */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app * {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}
.app *::-webkit-scrollbar {
  display: none;
}


.crisp-client  div div div div[data-mode=chat] {
  padding-top: var(--gap-top) !important;
}
.crisp-client  div div div div[data-mode=chat] > div + span > span {
  top: var(--gap-top) !important;
}

@media only screen and (min-width: 765px) {
  .crisp-client .cc-tlyw .cc-kxkl .cc-nsge .cc-imbb {
    margin-bottom: 65px !important;
  }
}
